import Typography from '../../commons/Typography/Typography';
import '../../css/ScrollbarStyles.css';
import { useNavigate } from 'react-router';
import useUsers, { IFilters } from '../../state/hooks/users/useUsers';
import { Fragment, useEffect, useState } from 'react';
import { FiltersComponent } from '../../commons/FiltersComponent/FiltersComponent';
import formatDate from "../../utils/helpers/formatDate";

const filterOptions = [
  { value: 'email', label: 'Email' },
  { value: 'verified', label: 'Verified' },
  { value: 'search', label: 'Search' },
  { value: 'id', label: 'ID' },
];

const Users = () => {
  const navigate = useNavigate();
  const [filtersCount, setFiltersCount] = useState<IFilters>({});
  const [activeFilters, setActiveFilters] = useState<IFilters>({});
  const ITEMS_PER_PAGE = 10;

  const {
    data,
    isLoading,
    isError,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useUsers(true, { ...activeFilters, limit: ITEMS_PER_PAGE });

  useEffect(() => {
    addFilter();
  }, []);

  const addFilter = () => {
    const newFilter = filterOptions.find(
      (option) => !Object.keys(filtersCount).includes(option.value)
    );
    if (newFilter) {
      setFiltersCount((prevFilters) => ({
        ...prevFilters,
        [newFilter.value]: '',
      }));
    }
  };

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;

    if (bottom) {
      if (isFetchingNextPage && hasNextPage) return;
      fetchNextPage();
    }

  };

  const handleSearch = () => {
    setActiveFilters(filtersCount);
  };

  return (
    <div className="flex px-30 pt-55 pb-20 flex-col bg-light-grey-1 h-full w-full">
      <h2 className="text-dark-grey font-bold text-2xl mb-30">Users</h2>
      <FiltersComponent
        setFiltersCount={setFiltersCount}
        filterOptions={filterOptions}
        filtersCount={filtersCount}
        onSearch={handleSearch}
      />
      <div
        className="overflow-x-auto rounded-xl border-light-grey-3 border-solid border"
        onScroll={handleScroll}
      >
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>{JSON.stringify(error)}</div>
        ) : (
          <table className="table-auto w-full overflow-x-auto bg-white rounded-xl border-collapse text-dark-grey">
            <thead className="sticky top-0">
              <tr className=" bg-gray-100 h-48 bg-light-grey-4">
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Email</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Verified</Typography>
                </th>

                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Creation Date</Typography>
                </th>

                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Verification Date</Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.pages.map((userList) => (
                <Fragment key={userList.meta.page}>
                  {userList.data?.map((user) => (
                    <tr
                      className="cursor-pointer border-light-grey-3 border-solid border-t hover:bg-light-grey-1"
                      key={user.id}
                      onClick={() => navigate(`/main-users/${user.id}`)}
                    >
                      <td className="px-20 py-20">
                        <Typography weight="semibold">{user.email}</Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {`${user.verified}`}
                        </Typography>
                      </td>

                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {`${formatDate(user.createdAt) || ''}`}
                        </Typography>
                      </td>

                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {`${formatDate(user.verificationDate) || ''}`}
                        </Typography>
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Users;
