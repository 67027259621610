import React, {useEffect, useState} from 'react';
import Button from "../../commons/Button/Button";
import "../../css/ScrollbarStyles.css";
import { FormField } from '../../commons/FormField/FormField';
import { useFormik } from 'formik';
import {useNavigate} from "react-router";
import useUserExport from "../../state/hooks/users/useUserExport";
import useMe from "../../state/hooks/auth/useMe";


interface IDecoded{
    data:{
        roles:any[]
    }
}

const Exports = () => {
    const { data: meData, isLoading: mLoading } = useMe();
    const formik = useFormik({
        initialValues: {
            startDate: "",
            endDate: "",
        },
        onSubmit: () => { },
    });

    const [data, setData] = useState<any>()


    const handleExportClick = () => {
        useUserExport(false).then((data) => {
            setData(data);
        })

    };

    const navigate = useNavigate();



    useEffect(() => {


        if (meData) {
            const { data } = meData;

            if (data) {
                const hasPermission = data.roles?.some((role: { permissions: { key: string; }[]; }) =>
                    role?.permissions?.some((permission: { key: string }) => permission.key === 'generate:users:report')
                );

                if (!hasPermission) {
                    alert("You can't access this tab, Permission denied");
                    navigate("/sub-users");
                }
            }
        }
    }, []);


    useEffect(() => {
        if(data) {
            const message  = data.message as any
          alert(message || 'Exported successfully')
        }

    }, [data]);

    return (
        <div className="flex overflow-x-auto px-30 pt-80 pb-20 flex-col bg-light-grey-1 h-full">
            <h2 className="text-dark-grey font-bold text-2xl mb-50">Exports</h2>
            <form onSubmit={(e) => e.preventDefault()}>
                {/*<FormField label="Export stats" style='text-lg'>*/}
                {/*    <Button type="secondary" id="Exports.ExportStatsButton" border >*/}
                {/*        Export*/}
                {/*    </Button>*/}
                {/*</FormField>*/}
                {/*<FormField label="Start date">*/}
                {/*    <TextInput*/}
                {/*        customStyles="flex-col w-360"*/}
                {/*        placeholder={""}*/}
                {/*        customInputStyles="h-12 w-full"*/}
                {/*        type="date"*/}
                {/*        onChange={(e) => {*/}
                {/*            formik.handleChange("startDate")(e.target.value);*/}
                {/*        }}*/}
                {/*        id="Exports.StartDateField"*/}
                {/*        value={formik.values.startDate}*/}
                {/*    />*/}
                {/*</FormField>*/}
                {/*<FormField label="End date">*/}
                {/*    <TextInput*/}
                {/*        customStyles="flex-col w-360"*/}
                {/*        placeholder={""}*/}
                {/*        customInputStyles="h-12 w-full"*/}
                {/*        type="date"*/}
                {/*        onChange={(e) => {*/}
                {/*            formik.handleChange("endDate")(e.target.value);*/}
                {/*        }}*/}
                {/*        id="Exports.EndDateField"*/}
                {/*        value={formik.values.endDate}*/}
                {/*    />*/}
                {/*</FormField>*/}
                <FormField label="User export" style='text-lg'>
                    <Button type="secondary" id="Exports.ExportUserButton" border onClick={handleExportClick}>
                        Export
                    </Button>
                </FormField>
                {/*<FormField label="User cancellation reasons export" style='text-lg'>*/}
                {/*    <Button type="secondary" id="Exports.ExportCancelReasonButton" border>*/}
                {/*        Export*/}
                {/*    </Button>*/}
                {/*</FormField>*/}
                {/*<FormField label="User review pop-up feedback export" style='text-lg'>*/}
                {/*    <Button type="secondary" id="Exports.ExportUserReviewButton" border>*/}
                {/*        Export*/}
                {/*    </Button>*/}
                {/*</FormField>*/}
            </form>
        </div>
    );
};

export default Exports;
