import React, { useEffect } from 'react';
import { Dispatch, SetStateAction } from 'react';
import Button from '../../commons/Button/Button';
import { Dropdown } from '../../commons/Dropdown/Dropdown';
import TextInput from '../../commons/TextInput/TextInput';
import { IFilters } from '../../state/hooks/users/useSubUsers';

interface FiltersComponentProps {
  filtersCount: IFilters;
  setFiltersCount: Dispatch<SetStateAction<IFilters>>;
  filterOptions: { value: string; label: string }[];
  onSearch?: () => void;
}

export const FiltersComponent: React.FC<FiltersComponentProps> = ({
  filtersCount,
  setFiltersCount,
  filterOptions,
  onSearch,
}) => {
  useEffect(() => {
    addFilter();
  }, []);

  const addFilter = () => {
    const newFilter = filterOptions.find(
      (option) => !Object.keys(filtersCount).includes(option.value)
    );
    if (newFilter) {
      setFiltersCount((prevFilters) => ({
        ...prevFilters,
        [newFilter.value]: '',
      }));
    }
  };

  return (
    <div className=" mb-20 flex gap-20">
      <Button
        type="secondary"
        border
        onClick={addFilter}
        style={{
          padding: '0 1.6rem',
          whiteSpace: 'nowrap',
        }}
      >
        Add Filter
      </Button>
      <div className="flex flex-wrap gap-10">
        {Object.keys(filtersCount).map((filter: string, index: number) => {
          const value = filtersCount[filter as keyof IFilters];
          return (
            <div key={index} className="flex gap-7">
              <Dropdown
                options={filterOptions.filter(
                  (option) => !Object.keys(filtersCount).includes(option.value)
                )}
                SelectedValue={filterOptions.find(
                  (option) => option.value === filter
                )}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    setFiltersCount((prevFilters) => {
                      const newFilters = { ...prevFilters };
                      delete newFilters[filter as keyof IFilters];
                      newFilters[selectedOption.value as keyof IFilters] =
                        undefined;
                      return newFilters;
                    });
                  }
                }}
                className="min-w-123 w-auto max-h-30"
              />

              <TextInput
                customInputStyles=" w-full h-30"
                onChange={(e) => {
                  const newFilters = {
                    ...filtersCount,
                    [filter]: e.target.value,
                  };
                  setFiltersCount(newFilters);
                }}
                value={value as string}
                placeholder="Containing"
              />
            </div>
          );
        })}
      </div>

      <div className="flex gap-10">
        {Object.keys(filtersCount).length > 0 && (
          <>
            <Button
              style={{
                maxHeight: 50,
                padding: '0 1.6rem',
                whiteSpace: 'nowrap',
              }}
              type="secondary"
              onClick={onSearch}
              border
            >
              Search
            </Button>

            <Button
              style={{
                maxHeight: 50,
                padding: '0 1.6rem',
                whiteSpace: 'nowrap',
              }}
              type="secondary"
              onClick={() => {
                setFiltersCount({});
              }}
              border
            >
              Clear All
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
