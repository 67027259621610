import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import axiosInstance from '../../../utils/axios';
import { defaultQueryOptions, QueryKeys } from '../../querys/querys.enum';
import { ISubscriptionsRepsonse } from '../../../models/Interface/Subscription.interface';

export interface IFileterInterface {
  value: string;
  label: string;
  search?: string;
}
export interface IFilters {
  search?: string;
  subscriptionId?: number;
  email?: string;
  limit?: number;
}

const useSubscriptions = (isEnabled: boolean, params: IFilters) => {
  return useInfiniteQuery<ISubscriptionsRepsonse>({
    queryKey: [QueryKeys.SUBSCRIPTIONS, params],
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1, queryKey }) => {
      const d = queryKey[1] as IFilters;
      const search = d.search ? `&search=${encodeURIComponent(d.search)}` : '';
      const subscriptionId = d.subscriptionId
        ? `&subscriptionId=${encodeURIComponent(d.subscriptionId)}`
        : '';
      const email = d.email ? `&email=${encodeURIComponent(d.email)}` : '';
      const limit = d.limit ? `&limit=${encodeURIComponent(d.limit)}` : '';
      
      return (
        await axiosInstance.get(
          `/subscriptions?page=${pageParam}${email}${search}${subscriptionId}${limit}`
        )
      ).data;
    },
    getNextPageParam: (lastPage, allPages, lastPageParam, allParams) => {
      if (lastPage.meta.totalPage === lastPage.meta.page) return undefined;
      return lastPage.meta.page + 1;
    },
    ...defaultQueryOptions,
    refetchOnWindowFocus: true,
    initialData: undefined,
    enabled: isEnabled,
    placeholderData: keepPreviousData,
  });
};
export default useSubscriptions;
