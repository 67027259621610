import Typography from '../../commons/Typography/Typography';
import '../../css/ScrollbarStyles.css';
import { Fragment, useEffect, useState } from 'react';
import { FiltersComponent } from '../../commons/FiltersComponent/FiltersComponent';
import FormatDate from '../../utils/helpers/formatDate';
import useB2BAdmins, {
  IFilters,
} from '../../state/hooks/b2b-admins/useB2BAdmins';

const filterOptions = [
  { value: 'email', label: 'Email' },
  { value: 'firstName', label: 'First Name' },
  { value: 'lastName', label: 'Last Name' },
  { value: 'search', label: 'Search' },
  { value: 'adminId', label: 'B2B admin ID' },
  { value: 'subscriptionStatus', label: 'Subscription Status' },
  { value: 'organizationName', label: 'Organization Name' },
];

const B2BAdmins = () => {
  const [filtersCount, setFiltersCount] = useState<IFilters>({});
  const [activeFilters, setActiveFilters] = useState<IFilters>({});

  const {
    data,
    isLoading,
    isError,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useB2BAdmins(true, activeFilters);

  useEffect(() => {
    addFilter();
  }, []);

  const addFilter = () => {
    const newFilter = filterOptions.find(
      (option) => !Object.keys(filtersCount).includes(option.value)
    );
    if (newFilter) {
      setFiltersCount((prevFilters) => ({
        ...prevFilters,
        [newFilter.value]: '',
      }));
    }
  };

  const handleSearch = () => {
    setActiveFilters(filtersCount);
  };

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;

    if (bottom) {
      if (isFetchingNextPage && hasNextPage) return;
      fetchNextPage();
    }
  };
  return (
    <div className="flex px-30 pt-55 pb-20 flex-col bg-light-grey-1 h-full w-full">
      <h2 className="text-dark-grey font-bold text-2xl mb-30">B2B Admins</h2>
      <FiltersComponent
        setFiltersCount={setFiltersCount}
        filterOptions={filterOptions}
        filtersCount={filtersCount}
        onSearch={handleSearch}
      />
      <div
        className="overflow-x-auto rounded-xl border-light-grey-3 border-solid border"
        onScroll={handleScroll}
      >
        {isLoading ? (
          <div>Loading...</div>
        ) : isError ? (
          <div>{JSON.stringify(error)}</div>
        ) : (
          <table className="table-auto w-full overflow-x-auto bg-white rounded-xl border-collapse text-dark-grey">
            <thead className="sticky top-0">
              <tr className="bg-gray-100 h-48 bg-light-grey-4">
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Email</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">First name</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Last name</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Organization name</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Package</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Status</Typography>
                </th>
                <th className="pl-20 text-left">
                  <Typography weight="extrabold">Creation Date</Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.pages.map((b2bAdminList) => (
                <Fragment key={b2bAdminList.meta.page}>
                  {b2bAdminList?.data?.map((b2bAdmin) => (
                    <tr
                      className="cursor-pointer border-light-grey-3 border-solid border-t hover:bg-light-grey-1"
                      key={b2bAdmin.id}
                      onClick={() => {
                        const url = `/b2b-admins/${b2bAdmin.id}`;
                        window.open(url, '_blank');
                      }}
                    >
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {b2bAdmin.email}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {b2bAdmin.firstName}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {b2bAdmin.lastName}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {b2bAdmin.organizations?.[0]?.organizationName ||
                            'N/A'}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {b2bAdmin.organizations?.[0]?.b2bSubscriptions?.[0]
                            ?.package || 'N/A'}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {b2bAdmin.active ? 'Active' : 'Inactive'}
                        </Typography>
                      </td>
                      <td className="px-20 py-20">
                        <Typography weight="semibold">
                          {FormatDate(b2bAdmin.createdAt) || ''}
                        </Typography>
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default B2BAdmins;
